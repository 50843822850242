<template>
  <section>
    <b-modal
      size="lg"
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      visible
      :ok-disabled="saveDisabled"
      :dialog-class="teams.theme"
      @cancel="cancelBtn"
      @ok="saveBtn"      
      centered
      :cancel-title="$t('CANCEL')"
      :ok-title="$t('SAVE')"
    >
      <b-form-group id="input-2" :label="$t('NAME') + ' *'" label-for="input-2">
        <b-form-input
          debounce="500"
          required
          v-model="AddressbookItem.name"
          aria-describedby="label-help-block"
          placeholder=""
        ></b-form-input>

        <div class="error-text" v-if="exists.name">
          {{ $t("ERROR.ALREADY_REGISTERED") }}
        </div>
      </b-form-group>

      <b-form-group
        v-if="type == 'FAX'"
        id="input-2"
        :label="$t('PHONE') + ' *'"
        label-for="input-2"
      >
        <Phone :exists="exists.phone" v-model="AddressbookItem.phone"></Phone>
        <div v-if="AddressbookItem.phone != ''">
          <div class="error-text" v-if="!validatePhone(AddressbookItem.phone)">
            {{ $t("ERROR.NOT_VALID") }}
          </div>
          <div class="error-text" v-if="exists.phone">
            {{ $t("ERROR.ALREADY_REGISTERED") }}
          </div>
        </div>
      </b-form-group>

      <b-form-group
        v-if="type != 'FAX'"
        id="input-2"
        :label="$t('EMAIL_PHONE') + ' *'"
        label-for="input-2"
      >
        <b-row no-gutters>
          <b-col class="mr-1">
            <b-form-input trim
              debounce="500"
              v-model="AddressbookItem.email"
              :state="stateEmail"
              aria-describedby="label-help-block"
              :placeholder="$t('EMAIL')"
            ></b-form-input>
            <div v-if="AddressbookItem.email != ''">
              <div
                class="error-text"
                v-if="!validateEmail(AddressbookItem.email)"
              >
                {{ $t("ERROR.NOT_VALID") }}
              </div>
              <div class="error-text" v-if="exists.email">
                {{ $t("ERROR.ALREADY_REGISTERED") }}
              </div>
            </div>
          </b-col>
          <b-col class="ml-1">
            <Phone v-model="AddressbookItem.phone" :exists="exists.phone"></Phone>
            <div v-if="AddressbookItem.phone != ''">
              <div
                class="error-text"
                v-if="!validatePhone(AddressbookItem.phone)"
              >
                {{ $t("ERROR.NOT_VALID") }}
              </div>
              <div class="error-text" v-if="exists.phone">
                {{ $t("ERROR.ALREADY_REGISTERED") }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form-group>

      <div v-if="settings.use_identifier && type != 'FAX'">
        
        <b-form-group class="mt-2" :label="$t('ADDRESSBOOK.IDENTIFIER')">
          <b-form-radio-group
            id="radio-group-1"
            v-model="AddressbookItem.identifier_format"
            :options="identifier_formats"
          ></b-form-radio-group>
        </b-form-group>
    

        <div v-if="AddressbookItem.identifier_format != ''">

          <Pnr
            v-model="AddressbookItem.identifier"
            v-if="AddressbookItem.identifier_format == 'SE_EID'" :exists="exists.identifier"
          ></Pnr>

          <div v-if="AddressbookItem.identifier != ''">
            <div
              class="error-text"
              v-if="
                !ValidateIdentifier(
                  AddressbookItem.identifier,
                  AddressbookItem.identifier_format
                )
              "
            >
              {{ $t("ERROR.NOT_VALID") }}
            </div>
            <div class="error-text" v-if="exists.identifier">
              {{ $t("ERROR.ALREADY_REGISTERED") }}
            </div>
          </div>

        </div>

      </div>
    </b-modal>
  </section>
</template>
<script>
import Pnr from "@/components/Input/Pnr.vue";
import Phone from "@/components/Input/Phone.vue";
export default {
  components: { Pnr, Phone },
  props: {
    settings: {
      type: Object,
      default: null,
    },
    functionbox_uuid: {
      type: String,
      default: null,
    },
    user_uuid: {
      type: String,
      default: null,
    },
    organisation_uuid: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      exists: {
        name: false,
        email: false,
        phone: false,
        identifier: false
      },
      AddressbookItem: {
        name: "",
        email: "",
        phone: "",
        identifier: "",
        identifier_format: "",
      },
      identifier_formats: [],
      use_identifier_formats: [
        { text: this.$t("IDENTIFIERS.SE_EID"), value: "SE_EID" },
      ],
      loading: false,
    };
  },
  methods: {
    ValidateIdentifier(identifier, format) {
      if (format == "SE_EID") {
        return this.validatePNum(identifier);
      }
      return false;
    },
    async CheckExists() {
      let self = this;
      this.$http
        .post(this.user.hostname + "/addressbook/exists", {
          organisation_uuid: this.currentOrganisationUuid,
          name: this.AddressbookItem.name,
          identifier: this.AddressbookItem.identifier,
          identifier_format: this.AddressbookItem.identifier_format,
          phone: this.AddressbookItem.phone,
          email: this.AddressbookItem.email,
          type: this.type,
          user_uuid: this.user_uuid,
          functionbox_uuid: this.functionbox_uuid,
        })
        .then((result) => {
          self.exists = result.data;
        })
        .catch(() => {
          self.exists = null;
        });
    },
    close() {
      this.AddressbookItem = {
        name: "",
        email: "",
        phone: "",
        identifier: "",
        identifier_format: "",
      };
      this.exists = {
        name: false,
        email: false,
        phone: false,
        identifier: false
      };
      this.$emit("close");
    },
    saveBtn() {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/addressbook/add", {
          organisation_uuid: this.currentOrganisationUuid,
          name: this.AddressbookItem.name.trim(),
          identifier: this.AddressbookItem.identifier.replace(/\s/g, ""),
          identifier_format: this.AddressbookItem.identifier_format,
          phone: this.AddressbookItem.phone,
          email: this.AddressbookItem.email,
          type: this.type,
          user_uuid: this.user_uuid,
          functionbox_uuid: this.functionbox_uuid,
        })
        .then(() => {
          self.loading = false;
          self.$emit("added");
          self.close();
        })
        .catch(() => {
          self.loading = false;
        });
    },
    cancelBtn() {
      this.close();
    },
  },
  computed: {
    stateEmail(){
      if(this.exists.email)
      {
        return false;
      }
      if(this.AddressbookItem.email == '')
      {
        return null;
      }
      return this.validateEmail(this.AddressbookItem.email);
    },
    currentOrganisationUuid() {
      if (this.organisation_uuid) {
        return this.organisation_uuid;
      }
      return this.user.information.organisation.organisation_uuid;
    },
    saveDisabled() {
      if (this.AddressbookItem.name == "") {
        return true;
      }
      if (
        this.AddressbookItem.phone == "" &&
        this.AddressbookItem.email == ""
      ) {
        return true;
      }
      if (this.exists == null) {
        return true;
      } else {
        if (this.exists != null && this.exists.name) {
          return true;
        }
        if (this.exists != null && this.exists.email) {
          return true;
        }
        if (this.exists != null && this.exists.phone) {
          return true;
        }
        if (this.exists != null && this.exists.identifier) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    "AddressbookItem.phone": function () {
      this.CheckExists();
    },
    "AddressbookItem.identifier": function () {
      this.CheckExists();
    },
    "AddressbookItem.email": function () {
      this.CheckExists();
    },
    "AddressbookItem.name": function () {
      this.CheckExists();
    },
  },
  mounted: function () {
    let self = this;
    this.identifier_formats = this.use_identifier_formats.filter(function (
      item
    ) {
      return self.settings.use_identifier_format.includes(item.value);
    });
    this.identifier_formats.unshift({ text: this.$t("NONE"), value: "" });
  },
};
</script>
  <style></style>
  