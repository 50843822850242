var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c(
        "section",
        [
          _c(
            "b-modal",
            {
              attrs: {
                size: "lg",
                "hide-header": "",
                "dialog-class": _vm.teams.theme,
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                visible: "",
                centered: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-footer",
                    fn: function ({ ok, cancel, hide }) {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              size: "sm",
                              variant: "primary",
                              disabled: _vm.saveDisabled,
                            },
                            on: { click: _vm.saveBtn },
                          },
                          [
                            _vm._v(
                              "\n        " + _vm._s(_vm.$t("SAVE")) + "\n      "
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-auto",
                            attrs: { size: "sm", variant: "secondary" },
                            on: { click: _vm.cancelBtn },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("CANCEL")) +
                                "\n      "
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "danger" },
                            on: { click: _vm.removeBtn },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("REMOVE")) +
                                "\n      "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1789573704
              ),
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-2",
                    label: _vm.$t("NAME") + " *",
                    "label-for": "input-2",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      debounce: "500",
                      required: "",
                      state: _vm.AddressbookItem.name == "" || !_vm.exists.name,
                      "aria-describedby": "label-help-block",
                      placeholder: "",
                    },
                    model: {
                      value: _vm.AddressbookItem.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.AddressbookItem, "name", $$v)
                      },
                      expression: "AddressbookItem.name",
                    },
                  }),
                  _vm.exists.name
                    ? _c("div", { staticClass: "error-text" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("ERROR.ALREADY_REGISTERED")) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.type == "FAX"
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-2",
                        label: _vm.$t("PHONE") + " *",
                        "label-for": "input-2",
                      },
                    },
                    [
                      _c("Phone", {
                        attrs: { exists: _vm.exists.phone },
                        model: {
                          value: _vm.AddressbookItem.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.AddressbookItem, "phone", $$v)
                          },
                          expression: "AddressbookItem.phone",
                        },
                      }),
                      _vm.AddressbookItem.phone != ""
                        ? _c("div", [
                            !_vm.validatePhone(_vm.AddressbookItem.phone)
                              ? _c("div", { staticClass: "error-text" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("ERROR.NOT_VALID")) +
                                      "\n        "
                                  ),
                                ])
                              : _vm._e(),
                            _vm.exists.phone
                              ? _c("div", { staticClass: "error-text" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t("ERROR.ALREADY_REGISTERED")
                                      ) +
                                      "\n        "
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type != "FAX"
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-2",
                        label: _vm.$t("EMAIL_PHONE") + " *",
                        "label-for": "input-2",
                      },
                    },
                    [
                      _c(
                        "b-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mr-1" },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  trim: "",
                                  debounce: "500",
                                  state:
                                    !_vm.exists.email &&
                                    (_vm.AddressbookItem.email == ""
                                      ? null
                                      : _vm.validateEmail(
                                          _vm.AddressbookItem.email
                                        )),
                                  "aria-describedby": "label-help-block",
                                  placeholder: _vm.$t("EMAIL"),
                                },
                                model: {
                                  value: _vm.AddressbookItem.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.AddressbookItem, "email", $$v)
                                  },
                                  expression: "AddressbookItem.email",
                                },
                              }),
                              _vm.AddressbookItem.email != ""
                                ? _c("div", [
                                    !_vm.validateEmail(
                                      _vm.AddressbookItem.email
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "error-text" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t("ERROR.NOT_VALID")
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.exists.email
                                      ? _c(
                                          "div",
                                          { staticClass: "error-text" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "ERROR.ALREADY_REGISTERED"
                                                  )
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "ml-1" },
                            [
                              _c("Phone", {
                                attrs: { exists: _vm.exists.phone },
                                model: {
                                  value: _vm.AddressbookItem.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.AddressbookItem, "phone", $$v)
                                  },
                                  expression: "AddressbookItem.phone",
                                },
                              }),
                              _vm.AddressbookItem.phone != ""
                                ? _c("div", [
                                    !_vm.validatePhone(
                                      _vm.AddressbookItem.phone
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "error-text" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t("ERROR.NOT_VALID")
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.exists.phone
                                      ? _c(
                                          "div",
                                          { staticClass: "error-text" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "ERROR.ALREADY_REGISTERED"
                                                  )
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.settings.use_identifier && _vm.type != "FAX"
                ? _c(
                    "div",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mt-2",
                          attrs: { label: _vm.$t("ADDRESSBOOK.IDENTIFIER") },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "radio-group-1",
                              options: _vm.identifier_formats,
                            },
                            model: {
                              value: _vm.AddressbookItem.identifier_format,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.AddressbookItem,
                                  "identifier_format",
                                  $$v
                                )
                              },
                              expression: "AddressbookItem.identifier_format",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.AddressbookItem.identifier_format != ""
                        ? _c("div", [
                            _vm.AddressbookItem.identifier_format == "SE_EID"
                              ? _c(
                                  "div",
                                  [
                                    _vm.hideIdentifier
                                      ? _c("b-form-input", {
                                          attrs: {
                                            trim: "",
                                            type: "password",
                                            value: "HAS_VALUE",
                                          },
                                          on: { click: _vm.showIdentifier },
                                        })
                                      : _vm._e(),
                                    !_vm.hideIdentifier
                                      ? _c("Pnr", {
                                          attrs: {
                                            exists: _vm.exists.identifier,
                                          },
                                          on: { blur: _vm.blurPnr },
                                          model: {
                                            value:
                                              _vm.AddressbookItem.identifier,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.AddressbookItem,
                                                "identifier",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "AddressbookItem.identifier",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.AddressbookItem.identifier != ""
                              ? _c("div", [
                                  !_vm.hideIdentifier &&
                                  !_vm.ValidateIdentifier(
                                    _vm.AddressbookItem.identifier,
                                    _vm.AddressbookItem.identifier_format
                                  )
                                    ? _c("div", { staticClass: "error-text" }, [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.$t("ERROR.NOT_VALID")) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.exists.identifier
                                    ? _c("div", { staticClass: "error-text" }, [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t("ERROR.ALREADY_REGISTERED")
                                            ) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }