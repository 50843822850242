<template>
  <span v-if="settings && type">
    <slot v-if="settings.enabled && showBtn" name="activator" :onClick="onClick"></slot>
    
    <b-sidebar v-if="!isCompose && showAddressbook"
      sidebar-class="big_sidebar"
      visible
      right
      :title="$t('ADDRESSBOOK.TITLE')"
      backdrop
      no-slide
      lazy
      shadow
      no-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="view-content px-3 py-2">
        <List 
          :key="updateItemKey"         
          @selected="selectedItems"
          :settings="settings"
          :list="list"
          :functionbox_uuid="functionbox_uuid"
          :user_uuid="user_uuid"
          :message_type="message_type"
          :organisation_uuid="organisation_uuid"
          :isCompose="isCompose"></List>      

          <hr class="p-0 m-0 mt-4 mb-2" />
          
        <b-button
        @click="closeBtn"
        class="ml-2"
        v-b-tooltip.hover
        :title="$t('CLOSE')"
      >
        {{ $t("CLOSE") }}
      </b-button>
      <b-button
        v-if="selected.length != 0 && !isCompose && userCanEdit"
        @click="removeItems"
        variant="danger"
        class="mr-2 float-right"
      >
        {{ $t("REMOVE") }}
      </b-button>
      </div>
    </b-sidebar>

    <b-modal
      v-if="isCompose && showAddressbook"
      :dialog-class="teams.theme"
      size="lg"
      hide-header
      no-close-on-backdrop
      @hidden="closeBtn"
      visible
      :ok-disabled="selectDisabled"
      @cancel="closeBtn"
      @ok="selectBtn"
      centered
      :cancel-title="$t('CANCEL')"
      :ok-title="$t('SELECT')"
    >
    <List 
    @selected="selectedItems"
    :key="updateItemKey"
    :settings="settings"
    :single-select="singleSelect"
    :is-number="isNumber"
    :list="list"
    :functionbox_uuid="functionbox_uuid"
    :user_uuid="user_uuid"
    :message_type="message_type"
    :organisation_uuid="organisation_uuid"
    :isCompose="isCompose"></List>     
</b-modal>

    
  </span>
</template>
<script>
import List from "@/components/AddressBook/List.vue";
export default {
  components: { List },
  props: {
    isNumber: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    singleSelect: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    list: {
      default: () => [],
      type: Array,
    },
    functionbox_uuid: {
      type: String,
      default: null,
    },
    user_uuid: {
      type: String,
      default: null,
    },
    organisation_uuid: {
      type: String,
      default: null,
    },
    message_type: {
      type: String,
      default: "",
    },
    isCompose: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  data() {
    return {
      showAddressbook: false,
      settings: null,
      loading: false,
      userCanEdit: true,
      selected: [],
      updateItemIndex: 0
    };
  },  
  methods: {
    selectedItems(items){
      this.selected = items.map(obj => ({ ...obj, item_type: 'addressbook' }));
    },
    selectBtn(){
      this.$emit("selected", this.selected);
      this.showAddressbook = false;
    },
    onClick() {
      this.showAddressbook = true;
    },
    closeBtn() {
      this.$emit('close');
      this.updateItemIndex++;
      this.showAddressbook = false;
    },
    async shownSidebar(){
      console.log("SHOWING");
      await this.CanEdit();
    },
    async CanEdit() {
      try {
        this.loading = true;
        let result = await this.$http.post(this.user.hostname + "/addressbook/can-edit",{
          setting: "AddressBook",
          organisation_uuid: this.currentOrganisationUuid,
          functionbox_uuid: this.functionbox_uuid,
        });            
        this.userCanEdit = result.data;
        this.loading = false;                        
      } catch { 
        this.loading = false;
        console.log("Could not getSettings"); 
      }
    },
    async getSettings() {
      try {
        this.loading = true;
        let result = await this.$http.post(this.user.hostname + "/settings/get", {
          setting: "AddressBook",
          organisation_uuid: this.currentOrganisationUuid,
          functionbox_uuid: this.functionbox_uuid,
        });            
        result.data.use_identifier_format = result.data.use_identifier_format.split(",");
        this.settings = result.data;
        this.loading = false;                        
      } catch { 
        this.loading = false;
        console.log("Could not getSettings"); 
      }
    },
    removeItems: function () {
      this.loading = true;
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {      
            self.$http
              .post(
                self.user.hostname + "/addressbook/bulk/remove",
                self.selected
              )
              .then(() => {
                self.loading = false;
                self.updateItemIndex++;
                self.selected = [];
                self.$noty.info(self.$t("REMOVED"));
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
  },
  computed: {
    showBtn(){
      if(this.user_uuid != "")
      {
        return false;
      }
      if(!this.isCompose && !this.userCanEdit)
      {
        return false;
      }
      return true;
    },
    type(){
      return this.message_type == 'fax-message' ? 'FAX' : 'SEFOS'
    },
    selectDisabled(){
      return this.selected.length == 0 && this.showAddBtn;
    },
    updateItemKey(){
      return "updateItemIndex_" + this.updateItemIndex;
    },
    currentOrganisationUuid() {
      if (this.organisation_uuid) {
        return this.organisation_uuid;
      }
      return this.user.information.organisation.organisation_uuid;
    },
    redrawKey: function () {
      return "redrawAddressbookKey_" + this.redrawIndex;
    },
    faxVisible() {
      if (this.functionbox_uuid != "") {
        let self = this;
        let fb = this.user.functionboxes.items.filter(function (item) {
          return item.functionbox_uuid == self.functionbox_uuid;
        });
        if (fb.length == 0) {
          return false;
        } else {
          if (fb[0].fax_enabled != undefined) {
            return fb[0].fax_enabled;
          }
        }
      }
      return false;
    }
  },
  mounted: function () {
    this.getSettings();
    this.CanEdit();
  },
};
</script>
  <style></style>
  