<template>
  <span v-if="settings && type">
    <div>
      <b-form inline class="mt-2">
        <b-button @click="refreshList" class="no-border"
          ><i class="fal fa-sync"></i
          ><span class="sr-only">{{ $t("REFRESH") }}</span></b-button
        >
        <b-button
          variant="dark"
          v-if="showAddBtn"
          v-b-tooltip.hover
          :title="$t('ADD')"
          @click="showAddItem"
        >
          <i class="fal fa-plus"></i> {{ $t("ADD") }}</b-button
        >
        <label class="sr-only" for="inline-form-input-search">{{
          $t("SEARCH")
        }}</label>
        <b-form-input trim
          id="inline-form-input-search"
          v-model="searchText"
          :placeholder="$t('SEARCH')"
          @keydown.enter.prevent
          name="addressbook_search"
          class="i-100 searchText"
        ></b-form-input>

        <label class="sr-only" for="inline-form-input-limit">{{
          $t("LIMIT")
        }}</label>
        <b-form-select
          id="inline-form-input-limit"
          v-model="limit"
          class="limit"
          name="addressbook_limit"
          @change="getItems"
        >
          <b-form-select-option value="10">10</b-form-select-option>
          <b-form-select-option value="25">25</b-form-select-option>
          <b-form-select-option value="45">45</b-form-select-option>
          <b-form-select-option value="100">100</b-form-select-option>
        </b-form-select>
      </b-form>
    </div>

    <b-nav
      tabs
      v-if="functionbox_uuid && faxVisible && message_type == '' && !isCompose"
    >
      <b-nav-item :active="type == 'SEFOS'" @click="type = 'SEFOS'">{{
        $t("MESSAGES.TYPE.message")
      }}</b-nav-item>
      <b-nav-item :active="type == 'FAX'" @click="type = 'FAX'">{{
        $t("MESSAGES.TYPE.fax-message")
      }}</b-nav-item>
    </b-nav>

    <b-table
      :key="redrawKey"
      small
      :hover="!isCompose"
      class="mt-2"
      :busy="loading"
      :items="items"
      :fields="fields"
      @row-clicked="clickedRow"
    >
      <template v-slot:cell(type)="data">
        <div v-if="isCompose">
          {{ data.item.name }}
        </div>
        <div v-if="!isCompose">
          <b-form-checkbox
            v-if="CanEdit"
            v-model="selected"
            name="check-button"
            :value="data.item.id"
          >
          </b-form-checkbox>
          {{ data.item.name }}
        </div>
        <div v-if="!isNumber">
          <b-form-checkbox
            v-if="isCompose && data.item.email != ''"
            v-model="selected"
            :disabled="checkDisabled('email', data.item)"
            name="check-button"
            :value="data.item.email"
            v-b-tooltip.hover
            :title="data.item.email"
            >{{ Truncate(data.item.email,15) }}
          </b-form-checkbox>
          <span v-if="isCompose && data.item.email == ''">{{
            data.item.email
          }}</span>
          <span v-if="!isCompose">{{ data.item.email }}</span>
        </div>
        <div>
          <b-form-checkbox
            v-if="isCompose && data.item.phone != ''"
            v-model="selected"
            :disabled="checkDisabled('phone', data.item)"
            name="check-button"
            :value="data.item.phone"
            v-b-tooltip.hover
            :title="data.item.phone"
            >{{ Truncate(data.item.phone,15) }}
          </b-form-checkbox>
          <span v-if="isCompose && data.item.phone == ''">{{
            data.item.phone
          }}</span>
          <span v-if="!isCompose">{{ data.item.phone }}</span>
        </div>
      </template>

      <template v-slot:cell(created_date)="data">{{
        FormatDateTime(data.item.created_date)
      }}</template>

      <template v-slot:cell(name)="data">
        <span v-if="isCompose" v-b-tooltip.hover :title="data.item.name">{{
          Truncate(data.item.name,15)
        }}</span>
        <span v-if="!isCompose">{{ data.item.name }}</span>
      </template>

      <template v-slot:cell(id)="data">
        <b-form-checkbox
          v-if="!isCompose && CanEdit"
          v-model="selected"
          name="check-button"
          :value="data.item.id"
          ><label class="sr-only" for="inline-form-input-limit">{{
            data.item.id
          }}</label></b-form-checkbox
        >
      </template>

      <template v-slot:cell(email)="data">
        <b-form-checkbox
          v-if="isCompose && data.item.email != ''"
          v-model="selected"
          :disabled="checkDisabled('email', data.item)"
          name="check-button"
          :value="data.item.email"
          v-b-tooltip.hover
          :title="data.item.email"
          >{{ Truncate(data.item.email,15) }}
        </b-form-checkbox>
        <span v-if="isCompose && data.item.email == ''">{{
          data.item.email
        }}</span>
        <span v-if="!isCompose">{{ data.item.email }}</span>
      </template>

      <template v-slot:cell(phone)="data">
        <b-form-checkbox
          v-if="isCompose && data.item.phone != ''"
          v-model="selected"
          :disabled="checkDisabled('phone', data.item)"
          name="check-button"
          :value="data.item.phone"
          v-b-tooltip.hover
          :title="data.item.phone"
          >{{ Truncate(data.item.phone,15) }}
        </b-form-checkbox>
        <span v-if="isCompose && data.item.phone == ''">{{
          data.item.phone
        }}</span>
        <span v-if="!isCompose">{{ data.item.phone }}</span>
      </template>

      <template v-slot:cell(identifier)="data">
        <span v-if="data.item.identifier_format != ''">{{
          GetIdentifierType(data.item.identifier_format)
        }}</span>
      </template>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalItems"
      :per-page="limit"
      align="center"
      @input="getItems"
      pills
    >
    </b-pagination>

    <AddItem
      v-if="showAdd"
      @close="closeAddItem"
      @added="getItems"
      :settings="settings"
      :functionbox_uuid="functionbox_uuid"
      :user_uuid="user_uuid"
      :organisation_uuid="organisation_uuid"
      :type="type"
    ></AddItem>

    <UpdateItem
      :key="updateItemKey"
      v-if="currentItem && !isCompose"
      :item="currentItem"
      @updated="getItems"
      @close="closeUpdateItem"
      :settings="settings"
      :functionbox_uuid="functionbox_uuid"
      :user_uuid="user_uuid"
      :organisation_uuid="organisation_uuid"
      :type="type"
    ></UpdateItem>
  </span>
</template>
<script>
import AddItem from "@/components/AddressBook/AddItem.vue";
import UpdateItem from "@/components/AddressBook/UpdateItem.vue";
export default {
  components: { AddItem, UpdateItem },
  props: {
    isNumber: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    singleSelect: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    list: {
      default: () => [],
      type: Array,
    },
    settings: {
      type: Object,
      default: null,
    },
    functionbox_uuid: {
      type: String,
      default: null,
    },
    user_uuid: {
      type: String,
      default: null,
    },
    organisation_uuid: {
      type: String,
      default: null,
    },
    message_type: {
      type: String,
      default: "",
    },
    isCompose: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  data() {
    return {
      updateItemIndex: 0,
      currentItem: null,
      type: this.message_type == "fax-message" ? "FAX" : "SEFOS",
      add_all: false,
      showAddressbook: false,
      use_identifier_formats: [
        { text: this.$t("IDENTIFIERS.SE_EID"), value: "SE_EID" },
      ],
      showAdd: false,
      redrawIndex: 0,
      searchText: "",
      limit: 10,
      loading: false,
      currentPage: 1,
      selected: [],
      items: [],
      totalItems: 0,
    };
  },
  methods: {
    checkDisabled(type, address) {
      if (type == "phone") {
        if (this.singleSelect && this.selected.length > 0) {
          if(this.selected[0] == address.phone)
          {
            return false;         
          }else{
            return true;
          }
        }
        if (this.selected.includes(address.email)) {
          return true;
        }
        if (this.validatePhone(address)) {
          return true;
        }
        return false;
      } else {
        if (this.isNumber) {
          return true;
        }
        if (this.singleSelect && this.selected.length > 0) {
          if(this.selected[0] == address.email)
          {
            return false;         
          }else{
            return true;
          }
        }
        if (this.selected.includes(address.phone)) {
          return true;
        }
        if (this.validateEmail(address)) {
          return true;
        }
        return false;
      }
    },
    closeUpdateItem(reload) {
      this.currentItem = null;
      if (reload) {
        this.updateItemIndex++;
        this.getItems();
      }
    },
    clickedRow(AddressbookItem) {
      if (!this.isCompose && this.CanEdit) {
        this.currentItem = AddressbookItem;
        this.updateItemIndex++;
      }
      if(this.isCompose && this.singleSelect)
      {
        return "MUH";
      }
    },
    showAddItem() {
      this.showAdd = true;
    },
    closeAddItem() {
      this.showAdd = false;
    },
    GetIdentifierType(format) {
      return this.$t("IDENTIFIERS." + format);
    },
    addAll: function () {
      if (this.add_all) {
        this.selected = this.items.map((a) => a.id);
      } else {
        this.selected = [];
      }
    },
    refreshList() {
      this.redrawIndex++;
      this.getItems();
    },
    getItems: function () {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/addressbook/list", {
          page: this.currentPage,
          limit: parseInt(this.limit),
          search_text: this.searchText,
          user_uuid: this.user_uuid,
          functionbox_uuid: this.functionbox_uuid,
          organisation_uuid: this.currentOrganisationUuid,
          type: this.type,
        })
        .then((response) => {
          self.loading = false;
          //self.items = response.data.items;
          self.items = response.data.items.filter(function (item) {
            return !(
              self.list.includes(item.email) || self.list.includes(item.phone)
            );
          });
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
  },
  computed: {
    selectedItems() {
      let self = this;
      let items = JSON.parse(
        JSON.stringify(
          this.items.filter(function (item) {
            return (
              self.selected.includes(item.email) ||
              self.selected.includes(item.phone) ||
              self.selected.includes(item.id)
            );
          })
        )
      );
      for (let ix = 0; ix < items.length; ix++) {
        let findPhone = this.selected.includes(items[ix].phone);
        let findEmail = this.selected.includes(items[ix].email);
        if (findPhone) {
          items[ix].email = "";
        }
        if (findEmail) {
          items[ix].phone = "";
        }
      }
      return items;
    },
    updateItemKey() {
      return "updateItemIndex_" + this.updateItemIndex;
    },
    CanEdit() {
      if (
        this.functionbox_uuid != "" &&
        this.settings.admin_edit &&
        this.user.information.is_admin == 0
      ) {
        let self = this;
        let boxes = this.user.functionboxes.items.filter(function (item) {
          return item.functionbox_uuid == self.functionbox_uuid;
        });
        if (boxes.length > 0) {
          if (boxes[0].is_admin == 0) {
            return false;
          }
        }
      }
      return true;
    },
    showAddBtn() {
      if (this.isCompose) {
        return false;
      }
      return this.CanEdit;
    },
    fields() {
      let fields = [];
      fields.push({
        key: "id",
        label: "",
        class: "text-break clickable hidden-mobile",
        thClass: "hidden-mobile",
        sortable: false,
      });
      fields.push({
        key: "type",
        label: "",
        class: "text-break clickable hidden-not-mobile",
        thClass: "hidden-not-mobile",
        sortable: false,
      });
      fields.push({
        key: "name",
        label: this.$t("NAME"),
        class: "text-break clickable hidden-mobile",
        thClass: "hidden-mobile",
        sortable: false,
      });
      if (!this.isNumber) {
        fields.push({
          key: "email",
          label: this.$t("EMAIL"),
          class: "text-break clickable hidden-mobile",
          thClass: "hidden-mobile",
          sortable: false,
        });
      }
      fields.push({
        key: "phone",
        label: this.$t("PHONE"),
        class: "text-break clickable hidden-mobile",
        thClass: "hidden-mobile",
        sortable: false,
      });
      if (!this.isNumber) {
        if (this.settings.use_identifier) {
          fields.push({
            key: "identifier",
            label: this.$t("IDENTIFIER"),
            class: "text-break clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          });
        }
      }
      return fields;
    },
    currentOrganisationUuid() {
      if (this.organisation_uuid) {
        return this.organisation_uuid;
      }
      return this.user.information.organisation.organisation_uuid;
    },
    redrawKey: function () {
      return "redrawAddressbookKey_" + this.redrawIndex;
    },
    faxVisible() {
      if (this.functionbox_uuid != "") {
        let self = this;
        let fb = this.user.functionboxes.items.filter(function (item) {
          return item.functionbox_uuid == self.functionbox_uuid;
        });
        if (fb.length == 0) {
          return false;
        } else {
          if (fb[0].fax_enabled != undefined) {
            return fb[0].fax_enabled;
          }
        }
      }
      return false;
    },
  },
  watch: {
    selectedItems: function (newValue) {
      this.$emit("selected", newValue);
    },
    list: function () {
      this.getItems();
    },
    type: function () {
      this.getItems();
    },
    searchText: function () {
      if (this.searchText.length == 0) {
        this.getItems();
      }
      if (this.searchText.length >= 2) {
        this.getItems();
      }
    },
  },
  mounted: function () {
    this.getItems();
  },
};
</script>
  <style></style>
  