var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings && _vm.type
    ? _c(
        "span",
        [
          _c(
            "div",
            [
              _c(
                "b-form",
                { staticClass: "mt-2", attrs: { inline: "" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "no-border",
                      on: { click: _vm.refreshList },
                    },
                    [
                      _c("i", { staticClass: "fal fa-sync" }),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(_vm._s(_vm.$t("REFRESH"))),
                      ]),
                    ]
                  ),
                  _vm.showAddBtn
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          attrs: { variant: "dark", title: _vm.$t("ADD") },
                          on: { click: _vm.showAddItem },
                        },
                        [
                          _c("i", { staticClass: "fal fa-plus" }),
                          _vm._v(" " + _vm._s(_vm.$t("ADD"))),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "label",
                    {
                      staticClass: "sr-only",
                      attrs: { for: "inline-form-input-search" },
                    },
                    [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                  ),
                  _c("b-form-input", {
                    staticClass: "i-100 searchText",
                    attrs: {
                      trim: "",
                      id: "inline-form-input-search",
                      placeholder: _vm.$t("SEARCH"),
                      name: "addressbook_search",
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "sr-only",
                      attrs: { for: "inline-form-input-limit" },
                    },
                    [_vm._v(_vm._s(_vm.$t("LIMIT")))]
                  ),
                  _c(
                    "b-form-select",
                    {
                      staticClass: "limit",
                      attrs: {
                        id: "inline-form-input-limit",
                        name: "addressbook_limit",
                      },
                      on: { change: _vm.getItems },
                      model: {
                        value: _vm.limit,
                        callback: function ($$v) {
                          _vm.limit = $$v
                        },
                        expression: "limit",
                      },
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: "10" } }, [
                        _vm._v("10"),
                      ]),
                      _c("b-form-select-option", { attrs: { value: "25" } }, [
                        _vm._v("25"),
                      ]),
                      _c("b-form-select-option", { attrs: { value: "45" } }, [
                        _vm._v("45"),
                      ]),
                      _c("b-form-select-option", { attrs: { value: "100" } }, [
                        _vm._v("100"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.functionbox_uuid &&
          _vm.faxVisible &&
          _vm.message_type == "" &&
          !_vm.isCompose
            ? _c(
                "b-nav",
                { attrs: { tabs: "" } },
                [
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.type == "SEFOS" },
                      on: {
                        click: function ($event) {
                          _vm.type = "SEFOS"
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("MESSAGES.TYPE.message")))]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.type == "FAX" },
                      on: {
                        click: function ($event) {
                          _vm.type = "FAX"
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("MESSAGES.TYPE.fax-message")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("b-table", {
            key: _vm.redrawKey,
            staticClass: "mt-2",
            attrs: {
              small: "",
              hover: !_vm.isCompose,
              busy: _vm.loading,
              items: _vm.items,
              fields: _vm.fields,
            },
            on: { "row-clicked": _vm.clickedRow },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(type)",
                  fn: function (data) {
                    return [
                      _vm.isCompose
                        ? _c("div", [
                            _vm._v(
                              "\n        " + _vm._s(data.item.name) + "\n      "
                            ),
                          ])
                        : _vm._e(),
                      !_vm.isCompose
                        ? _c(
                            "div",
                            [
                              _vm.CanEdit
                                ? _c("b-form-checkbox", {
                                    attrs: {
                                      name: "check-button",
                                      value: data.item.id,
                                    },
                                    model: {
                                      value: _vm.selected,
                                      callback: function ($$v) {
                                        _vm.selected = $$v
                                      },
                                      expression: "selected",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(
                                "\n        " +
                                  _vm._s(data.item.name) +
                                  "\n      "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isNumber
                        ? _c(
                            "div",
                            [
                              _vm.isCompose && data.item.email != ""
                                ? _c(
                                    "b-form-checkbox",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      attrs: {
                                        disabled: _vm.checkDisabled(
                                          "email",
                                          data.item
                                        ),
                                        name: "check-button",
                                        value: data.item.email,
                                        title: data.item.email,
                                      },
                                      model: {
                                        value: _vm.selected,
                                        callback: function ($$v) {
                                          _vm.selected = $$v
                                        },
                                        expression: "selected",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.Truncate(data.item.email, 15)
                                        ) + "\n        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isCompose && data.item.email == ""
                                ? _c("span", [_vm._v(_vm._s(data.item.email))])
                                : _vm._e(),
                              !_vm.isCompose
                                ? _c("span", [_vm._v(_vm._s(data.item.email))])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        [
                          _vm.isCompose && data.item.phone != ""
                            ? _c(
                                "b-form-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  attrs: {
                                    disabled: _vm.checkDisabled(
                                      "phone",
                                      data.item
                                    ),
                                    name: "check-button",
                                    value: data.item.phone,
                                    title: data.item.phone,
                                  },
                                  model: {
                                    value: _vm.selected,
                                    callback: function ($$v) {
                                      _vm.selected = $$v
                                    },
                                    expression: "selected",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.Truncate(data.item.phone, 15)) +
                                      "\n        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isCompose && data.item.phone == ""
                            ? _c("span", [_vm._v(_vm._s(data.item.phone))])
                            : _vm._e(),
                          !_vm.isCompose
                            ? _c("span", [_vm._v(_vm._s(data.item.phone))])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(created_date)",
                  fn: function (data) {
                    return [
                      _vm._v(
                        _vm._s(_vm.FormatDateTime(data.item.created_date))
                      ),
                    ]
                  },
                },
                {
                  key: "cell(name)",
                  fn: function (data) {
                    return [
                      _vm.isCompose
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: { title: data.item.name },
                            },
                            [_vm._v(_vm._s(_vm.Truncate(data.item.name, 15)))]
                          )
                        : _vm._e(),
                      !_vm.isCompose
                        ? _c("span", [_vm._v(_vm._s(data.item.name))])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(id)",
                  fn: function (data) {
                    return [
                      !_vm.isCompose && _vm.CanEdit
                        ? _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                name: "check-button",
                                value: data.item.id,
                              },
                              model: {
                                value: _vm.selected,
                                callback: function ($$v) {
                                  _vm.selected = $$v
                                },
                                expression: "selected",
                              },
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "sr-only",
                                  attrs: { for: "inline-form-input-limit" },
                                },
                                [_vm._v(_vm._s(data.item.id))]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(email)",
                  fn: function (data) {
                    return [
                      _vm.isCompose && data.item.email != ""
                        ? _c(
                            "b-form-checkbox",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: {
                                disabled: _vm.checkDisabled("email", data.item),
                                name: "check-button",
                                value: data.item.email,
                                title: data.item.email,
                              },
                              model: {
                                value: _vm.selected,
                                callback: function ($$v) {
                                  _vm.selected = $$v
                                },
                                expression: "selected",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.Truncate(data.item.email, 15)) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isCompose && data.item.email == ""
                        ? _c("span", [_vm._v(_vm._s(data.item.email))])
                        : _vm._e(),
                      !_vm.isCompose
                        ? _c("span", [_vm._v(_vm._s(data.item.email))])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(phone)",
                  fn: function (data) {
                    return [
                      _vm.isCompose && data.item.phone != ""
                        ? _c(
                            "b-form-checkbox",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: {
                                disabled: _vm.checkDisabled("phone", data.item),
                                name: "check-button",
                                value: data.item.phone,
                                title: data.item.phone,
                              },
                              model: {
                                value: _vm.selected,
                                callback: function ($$v) {
                                  _vm.selected = $$v
                                },
                                expression: "selected",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.Truncate(data.item.phone, 15)) +
                                  "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isCompose && data.item.phone == ""
                        ? _c("span", [_vm._v(_vm._s(data.item.phone))])
                        : _vm._e(),
                      !_vm.isCompose
                        ? _c("span", [_vm._v(_vm._s(data.item.phone))])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(identifier)",
                  fn: function (data) {
                    return [
                      data.item.identifier_format != ""
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.GetIdentifierType(
                                  data.item.identifier_format
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center my-2" },
                        [_c("b-spinner", { staticClass: "align-middle" })],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              676729855
            ),
          }),
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.totalItems,
              "per-page": _vm.limit,
              align: "center",
              pills: "",
            },
            on: { input: _vm.getItems },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
          _vm.showAdd
            ? _c("AddItem", {
                attrs: {
                  settings: _vm.settings,
                  functionbox_uuid: _vm.functionbox_uuid,
                  user_uuid: _vm.user_uuid,
                  organisation_uuid: _vm.organisation_uuid,
                  type: _vm.type,
                },
                on: { close: _vm.closeAddItem, added: _vm.getItems },
              })
            : _vm._e(),
          _vm.currentItem && !_vm.isCompose
            ? _c("UpdateItem", {
                key: _vm.updateItemKey,
                attrs: {
                  item: _vm.currentItem,
                  settings: _vm.settings,
                  functionbox_uuid: _vm.functionbox_uuid,
                  user_uuid: _vm.user_uuid,
                  organisation_uuid: _vm.organisation_uuid,
                  type: _vm.type,
                },
                on: { updated: _vm.getItems, close: _vm.closeUpdateItem },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }