<template>
  <section v-if="item">
    <b-modal
      size="lg"
      hide-header
      :dialog-class="teams.theme"
      no-close-on-backdrop
      no-close-on-esc
      visible
      centered
    >
    
      <b-form-group id="input-2" :label="$t('NAME') + ' *'" label-for="input-2">
        <b-form-input
          debounce="500"
          required
          v-model="AddressbookItem.name"
          :state="AddressbookItem.name == '' || !exists.name"
          aria-describedby="label-help-block"
          placeholder=""
        ></b-form-input>

        <div class="error-text" v-if="exists.name">
          {{ $t("ERROR.ALREADY_REGISTERED") }}
        </div>
      </b-form-group>

      <b-form-group
        v-if="type == 'FAX'"
        id="input-2"
        :label="$t('PHONE') + ' *'"
        label-for="input-2"
      >
        <Phone v-model="AddressbookItem.phone" :exists="exists.phone"></Phone>
        <div v-if="AddressbookItem.phone != ''">
          <div class="error-text" v-if="!validatePhone(AddressbookItem.phone)">
            {{ $t("ERROR.NOT_VALID") }}
          </div>
          <div class="error-text" v-if="exists.phone">
            {{ $t("ERROR.ALREADY_REGISTERED") }}
          </div>
        </div>
      </b-form-group>

      <b-form-group
        v-if="type != 'FAX'"
        id="input-2"
        :label="$t('EMAIL_PHONE') + ' *'"
        label-for="input-2"
      >
        <b-row no-gutters>
          <b-col class="mr-1">
            <b-form-input trim
              debounce="500"
              v-model="AddressbookItem.email"
              :state="!exists.email && (AddressbookItem.email == ''
                  ? null
                  : validateEmail(AddressbookItem.email)
              )"
              aria-describedby="label-help-block"
              :placeholder="$t('EMAIL')"
            ></b-form-input>
            <div v-if="AddressbookItem.email != ''">
              <div
                class="error-text"
                v-if="!validateEmail(AddressbookItem.email)"
              >
                {{ $t("ERROR.NOT_VALID") }}
              </div>
              <div class="error-text" v-if="exists.email">
                {{ $t("ERROR.ALREADY_REGISTERED") }}
              </div>
            </div>
          </b-col>
          <b-col class="ml-1">
            <Phone v-model="AddressbookItem.phone" :exists="exists.phone"></Phone>
            <div v-if="AddressbookItem.phone != ''">
              <div
                class="error-text"
                v-if="!validatePhone(AddressbookItem.phone)"
              >
                {{ $t("ERROR.NOT_VALID") }}
              </div>
              <div class="error-text" v-if="exists.phone">
                {{ $t("ERROR.ALREADY_REGISTERED") }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form-group>

      <div v-if="settings.use_identifier && type != 'FAX'">

        
        <b-form-group class="mt-2" :label="$t('ADDRESSBOOK.IDENTIFIER')">
          <b-form-radio-group
            id="radio-group-1"
            v-model="AddressbookItem.identifier_format"
            :options="identifier_formats"
          ></b-form-radio-group>
        </b-form-group>
        
        <div v-if="AddressbookItem.identifier_format != ''">

          <div v-if="AddressbookItem.identifier_format == 'SE_EID'">
            <b-form-input trim
              v-if="hideIdentifier"
              type="password"
              value="HAS_VALUE"
              @click="showIdentifier"
            ></b-form-input>
            <Pnr
              @blur="blurPnr"
              :exists="exists.identifier"
              v-if="!hideIdentifier"
              v-model="AddressbookItem.identifier"
            ></Pnr>
          </div>

          <div v-if="AddressbookItem.identifier != ''">
            <div
              class="error-text"
              v-if="
                !hideIdentifier && !ValidateIdentifier(
                  AddressbookItem.identifier,
                  AddressbookItem.identifier_format
                )
              "
            >
              {{ $t("ERROR.NOT_VALID") }}
            </div>
            <div class="error-text" v-if="exists.identifier">
              {{ $t("ERROR.ALREADY_REGISTERED") }}
            </div>
          </div>

        </div>


      </div>

      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="primary" :disabled="saveDisabled" @click="saveBtn">
          {{ $t('SAVE') }}
        </b-button>
        <b-button size="sm" variant="secondary" class="mr-auto" @click="cancelBtn">
          {{ $t('CANCEL') }}
        </b-button>
        <b-button size="sm" variant="danger" @click="removeBtn">
          {{ $t('REMOVE') }}
        </b-button>
      </template>
      
    </b-modal>
  </section>
</template>
<script>
import Pnr from "@/components/Input/Pnr.vue";
import Phone from "@/components/Input/Phone.vue";
export default {
  components: { Pnr, Phone },
  props: {
    item: {
      type: Object,
      default: null,
    },
    settings: {
      type: Object,
      default: null,
    },
    functionbox_uuid: {
      type: String,
      default: null,
    },
    user_uuid: {
      type: String,
      default: null,
    },
    organisation_uuid: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      exists: {
        name: false,
        email: false,
        phone: false,
        identifier: false
      },
      update_email: "",
      update_name: "",
      update_phone: "",
      update_identifier: "",
      update_identifier_format: "",
      hideIdentifier: false,
      AddressbookItem: this.item,
      identifier_formats: [],
      use_identifier_formats: [
        { text: this.$t("IDENTIFIERS.SE_EID"), value: "SE_EID" },
      ],
      loading: false,
    };
  },
  methods: {
    showIdentifier(){
      //console.log("blurHideIdentifier");
      if(this.AddressbookItem.identifier == "HAS_VALUE")
      {
        this.AddressbookItem.identifier = "";
      }
      this.hideIdentifier = false;
    },
    blurPnr(){
      if(this.update_identifier == "HAS_VALUE" && (this.AddressbookItem.identifier == "" || this.AddressbookItem.identifier == "HAS_VALUE"))
      {
        this.AddressbookItem.identifier = "HAS_VALUE";
        this.hideIdentifier = true;
      }
    },
    ValidateIdentifier(identifier, format) {
      if (format == "SE_EID") {
        return this.validatePNum(identifier);
      }
      return false;
    },
    async CheckExists() {
      let self = this;
      this.$http
        .post(this.user.hostname + "/addressbook/exists", this.AddressbookItem)
        .then((result) => {
          self.exists = result.data;
        })
        .catch(() => {
          self.exists = null;
        });
    },
    close() {
      let updated = this.IsUpdated;
      this.$emit("close",updated);
    },
    saveBtn() {
      this.AddressbookItem.name = this.AddressbookItem.name.trim();
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/addressbook/update", this.AddressbookItem)
        .then(() => {
          self.loading = false;
          self.$noty.info(self.$t("SAVED"));
          self.$emit("added");
          self.close();
        })
        .catch(() => {
          self.loading = false;
        });
    },
    cancelBtn() {
      this.close();
    },
    removeBtn: function () {
      this.loading = true;
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          dialogClass: this.teams.theme,
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {      
            self.$http
              .post(
                self.user.hostname + "/addressbook/remove",
                self.AddressbookItem
              )
              .then(() => {
                self.loading = false;
                self.update_email = self.AddressbookItem.email + "_REMOVED";
                self.close();
                self.$noty.info(self.$t("REMOVED"));
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
  },
  computed: {
    currentOrganisationUuid() {
      if (this.organisation_uuid) {
        return this.organisation_uuid;
      }
      return this.user.information.organisation.organisation_uuid;
    },
    IsUpdated(){
      return !(this.update_name == this.AddressbookItem.name && this.update_phone == this.AddressbookItem.phone && this.update_email == this.AddressbookItem.email && this.update_identifier == this.AddressbookItem.identifier && this.update_identifier_format == this.AddressbookItem.identifier_format);
    },
    saveDisabled() {
      if(!this.IsUpdated)
      {
        return true;
      }
      if (this.AddressbookItem.name == "") {
        return true;
      }
      if (
        this.AddressbookItem.phone == "" &&
        this.AddressbookItem.email == ""
      ) {
        return true;
      }
      if (this.exists == null) {
        return true;
      } else {
        if (this.exists != null && this.exists.name) {
          return true;
        }
        if (this.exists != null && this.exists.email) {
          return true;
        }
        if (this.exists != null && this.exists.phone) {
          return true;
        }
        if (this.exists != null && this.exists.identifier) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    "AddressbookItem.phone": function () {
      this.CheckExists();
    },
    "AddressbookItem.identifier": function () {
      if(this.AddressbookItem.identifier == "SE_EID")
      {
        if(this.AddressbookItem.identifier.length >= 12)
        {
          this.CheckExists();
        }
      }else{
        this.CheckExists();
      }
    },
    "AddressbookItem.identifier_format": function () {
      this.AddressbookItem.identifier = "";
    },
    "AddressbookItem.email": function () {
      this.CheckExists();
    },
    "AddressbookItem.name": function () {
      this.CheckExists();
    },
  },
  mounted: function () {
    let self = this;
    this.identifier_formats = this.use_identifier_formats.filter(function (
      item
    ) {
      return self.settings.use_identifier_format.includes(item.value);
    });
    this.identifier_formats.unshift({ text: this.$t("NONE"), value: "" });
    this.update_name = this.item.name + "";
    this.update_email = this.item.email + "";
    this.update_phone = this.item.phone + "";
    this.update_identifier = this.item.identifier + "";
    if(this.update_identifier == "HAS_VALUE")
    {
      this.hideIdentifier = true;
    }
    this.update_identifier_format = this.item.identifier_format + "";
  },
};
</script>
  <style></style>
  